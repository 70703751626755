<script setup lang="ts">
import { ParagraphSpecificTagsNews } from '~/api/graphql/generated'
import { useNewsSpecific } from '~/api/drupal'

const props = defineProps<{
  entity: ParagraphSpecificTagsNews
}>()

const visibleTags = props.entity.fieldVisibleTags
  ? props.entity.fieldVisibleTags
      .filter(Boolean)
      .map((tag) => tag.entity?.tid?.toString())
      .filter(Boolean)
  : []

const hiddenTags = props.entity.fieldHiddenTags
  ? props.entity.fieldHiddenTags
      .filter(Boolean)
      .map((tag) => tag.entity?.tid?.toString())
      .filter(Boolean)
  : []

const { items } = await useNewsSpecific(6, visibleTags, hiddenTags, props.entity.parentId || '0')

const data = computed(() => ({
  title: props.entity.fieldTitle || '',
  subTitle: props.entity.fieldDescription?.processed || '',
  teasers: items.value,
  link: {
    url: props.entity.fieldLink?.url?.path || '',
    target: props.entity.fieldLink?.options?.attributes.target || '_self',
    text: props.entity.fieldLink?.title || '',
  },
}))
</script>

<template>
  <TeaserSlider v-bind="data" />
</template>
